import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import Button from '../components/atoms/Button';

const Excerpt = styled.p`
	font-size: 0.95em;
`;

const BlogPostItem = ({
  index,
  title,
  slug,
  image,
  publishDate,
  author,
  category,
  categorySlug,
  page = 'blog',
  excerpt,
  className,
  avatar,
  body
}) => {
  const constructExcerptFromFirstParagraph = '';

  const regex = /<p>(.*?)<\/p>/;
  const corresp = regex.exec(body);
  const firstParagraphWithoutHtml = corresp ? corresp[1] : ''; // text1

  return (
    <div
      className={`${index == 0 ? 'w-full my-4' : 'pb-4 w-full md:w-1/3'
        } px-4`}
    >
      <div
        className={`${index == 0 ? 'flex flex-wrap' : 'h-full'
          } mb-4 rounded-2xl  shadow-md overflow-hidden  bg-white`}
      >
        <div
          className={`${index == 0 ? 'md:order-2 w-full md:w-1/2' : ''
            }`}
          style={{ margin: 0 }}
        >
          <GatsbyImage
            image={getImage(image)}
            alt={title}
            style={{
              width: '100%',
              height: index == 0 ? '100%' : '200px',
              objectFit: 'cover'
            }}
            className="h-full"
          />
        </div>
        <div
          className={`${index == 0
              ? 'w-full md:w-1/2 mb-0 m-0 md:p-12 '
              : ' flex flex-col '
            } p-8`}
        >
          <div className="flex-1">
            {' '}
            <p className="text-xs text-gray-500 uppercase font-semibold pb-2">
              {publishDate} BY {author}{' '}
              {category && (
                <>
                  in{' '}
                  <Link
                    className="text-brand-500 uppercase font-bold"
                    to={'/blog/category/' + categorySlug + '/'}
                    title={'View all posts in ' + category}
                  >
                    {category}
                  </Link>
                </>
              )}
            </p>
            <span
              className={`block font-display font-bold ${index == 0
                  ? 'text-xl md:text-2xl md:text-3xl'
                  : 'text-xl'
                }`}
            >
              {title}
            </span>
            <p className="text-gray-500 py-3 leading-6">
              {firstParagraphWithoutHtml
                .replace(/(<([^>]+)>)/gi, '') // Remove HTML tags
                .replace(/&#39;/g, "'") // Replace &#39; with '
                .slice(0, index == 0 ? 350 : 140)}
              ...
            </p>
          </div>
          <div>

            <Button
              to={`/${page}/` + slug}
              color="secondary"
              className="!border-1 !border-brand-500 "
              title={'Read more about ' + title}
            >
              Read More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPostItem;
